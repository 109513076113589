<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    :disableContextMenu="true"
    formTitle="Usuários do Sistema"
    :hasNewButton="false"
    :opts="opts"
    ref="detail"
    :resourceUrl="resourceUrl"
    @contextmenu="contextMenu"
  ></MasterDetail>
</template>

<script>
import { mapGetters } from "vuex";
import MasterDetail from "@/components/master-detail.vue";
import { UserTypeEnum } from "@/core/enums/user-types.js";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(["clientId", "selectedClient", "user"]),
    actionBarButtons: function () {
      return this.userHasAccessToAction("create")
        ? [
            {
              text: "Adicionar",
              icon: "mdi-plus-box-outline",
              action: () => {
                this.$router.push({ name: "cadastro-usuario" });
              },
            },
          ]
        : [];
    },
    cols: function () {
      return [
        { key: "id", name: "ID", align: 1, hideInform: true },
        { key: "nome", name: "Nome", type: this.$fieldTypes.TEXT },
        { key: "usuario", name: "Usuário", type: this.$fieldTypes.TEXT },
        {
          key: "tipo_usuario",
          name: "Tipo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipo", key: "id", name: "nome" },
        },
        {
          key: "ativo",
          name: "Status",
          align: 0,
          type: this.$fieldTypes.HTML,
          rel: { to: "usuarioStatus", key: "id", name: "nome" },
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ];
    },
    resourceUrl: function () {
      return `/v1/acesso/${this.clientId}/controleacesso`;
    },
  },
  data: function () {
    return {
      opts: {
        tipo: [
          { id: UserTypeEnum.ADMINISTRADOR, nome: "Administrador" },
          { id: UserTypeEnum.CONSULTOR, nome: "Consultor" },
          {
            id: UserTypeEnum.CLIENTE_ADMINISTRADOR,
            nome: "Cliente Administrador [SaaS]",
          },
          { id: UserTypeEnum.CLIENTE_CONSULTOR, nome: "Cliente Consultor [SaaS]" },
          { id: UserTypeEnum.GERENCIAL_CLIENTE, nome: "Gerencial Cliente" },
          { id: UserTypeEnum.COLABORADOR, nome: "Colaborador" },
        ],
        usuarioStatus: [
          { id: true, nome: '<span style="color: green">Ativo</span>' },
          { id: false, nome: '<span style="color: lightgray">Inativo</span>' },
        ],
      },
    };
  },
  methods: {
    contextMenu: function (v, e) {
      const menu = [
        {
          name: "Editar",
          limit: 1,
          disabled: v.tipo_usuario === UserTypeEnum.ADMINISTRADOR && this.user.tipo_usuario !== UserTypeEnum.ADMINISTRADOR,
          cb: (row) => {
            this.$router.push({
              name: "edicao-usuario",
              params: { id: row.id },
            });
          },
        },
      ];
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu,
      });
    },
  },
};
</script>